/* root.css */

/* Variables de colores */
:root {
  --primary-color: #3674b5;       /* Azul principal */
  --secondary-color: #578fca;     /* Azul secundario */
  --accent-color: #f0c713;        /* Azul claro */
  --background-color: #d1f8ef;    /* Fondo claro */
  --text-color: #333;             /* Color de texto principal */
  --text-light: #fff;             /* Texto claro */
  --shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra suave */
}

/* Fuentes */
:root {
  --font-title: 'Poppins', sans-serif;
  --font-text: 'Open Sans', sans-serif;
}

/* Reset básico */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Estilos globales */
body {
  font-family: var(--font-text);
  font-size: 16px;
  line-height: 1.6;
  color: var(--text-color);
  background-color: var(--background-color);
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-title);
  font-weight: 700;
  color: var(--primary-color);
}

a {
  color: var(--secondary-color);
  text-decoration: none;
}

a:hover {
  color: var(--primary-color);
  text-decoration: underline;
}

button {
  font-family: var(--font-title);
  font-weight: 600;
  background-color: var(--primary-color);
  color: var(--text-light);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: var(--secondary-color);
}

/* Clases utilitarias */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.text-center {
  text-align: center;
}

.shadow {
  box-shadow: var(--shadow);
}

/* Estilos para secciones */
.section {
  padding: 60px 0;
}

.section-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
}

.section-description {
  font-size: 1.1rem;
  max-width: 800px;
  margin: 0 auto 40px;
  text-align: center;
}

/* Estilos para tarjetas */
.card {
  background-color: var(--text-light);
  border-radius: 10px;
  padding: 20px;
  box-shadow: var(--shadow);
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
}

.card-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: var(--primary-color);
}

.card-text {
  font-size: 1rem;
  color: var(--text-color);
}

/*NavBar*/
/* Estilos específicos para el NavBar */
.isa-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.isa-navbar-scrolled {
  background-color: var(--text-light);
  box-shadow: var(--shadow);
}

.isa-navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.isa-navbar-logo-img {
  height: 40px;
}

.isa-navbar-links {
  display: flex;
  gap: 20px;
}

.isa-navbar-link {
  color: var(--text-light);
  text-decoration: none;
  font-weight: 600;
  font-family: var(--font-title);
  transition: color 0.3s ease;
}

.isa-navbar-scrolled .isa-navbar-link {
  color: var(--text-color);
}

.isa-navbar-link:hover {
  color: var(--primary-color);
}

.isa-navbar-language {
  position: relative;
}

.isa-navbar-language-select {
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid var(--primary-color);
  background-color: transparent;
  color: var(--text-light);
  font-family: var(--font-title);
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.isa-navbar-scrolled .isa-navbar-language-select {
  color: var(--text-color);
}

.isa-navbar-language-select:hover {
  background-color: var(--primary-color);
  color: var(--text-light);
}

/*HeroSection*/

/* HeroSection */
